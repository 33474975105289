.bambuser-wrapper {
	background-color: black;
	font-family: sans-serif;

	h1,h2 {
		font-family: sans-serif;
	}

	.wrapper-inner {
		padding: 12.5rem;
	}

	.section-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
		grid-template-areas:
			"heading heading"
			"title buttons";
		grid-gap: 1.25rem;
	}

	.text-wrapper {
		grid-area: heading;
		color: white;
	}

	.section-title {
		grid-area: title;
		display: flex;
		align-items: center;
		font-size: 2.5rem;
		color: white;
	}

	p {
		font-size: 1rem;
		text-transform: uppercase;
		letter-spacing: 0.125rem;
		font-weight: 400;
		.label {
			font-size: .75rem;
		}
	}

	h1 {
		font-size: 6.25rem;
		color: white;
		margin-bottom: 2rem;
	}

	ul {
		list-style: none;
		padding: 1.25rem;
		li {
			margin-bottom: 1.25rem;
		}
	}


	.button-wrapper {
		grid-area: buttons;
		button {
			cursor: pointer;
			text-transform: uppercase;
			width: 100%;
			background-color: transparent;
			border: .0625rem solid white;
			color: white;
			padding: 1rem;
			letter-spacing: .1875rem;
			&:hover {
				background-color: white;
				color: black;
			}
			p {
				margin: 0;
			}
		}
		.is-live {
			color: green;
			font-weight: bold;
		}
		.date {
			color: grey;
			font-weight: bold;
		}
	}

}

.show-miniplayer {
	display: block;
}

.hide-miniplayer {
	display: none !important;
}
